<template>
  <!-- eslint-disable -->
  <div class="bg-white">
    <div class="section-wrapper">
      <div class="subscription-dialog">
        <div class="subscription-dialog-header">
          <div>
            <h2>Honely Pro Subscription</h2>
            <!-- <p>Select payment method</p> -->
          </div>
          <div class="logo-wrapper">
            <img src="site_images/logo_honely.png" class="header-logo" alt="Honely" />
            <span>PRO</span>
          </div>
        </div>
        <div class="honely-pro-description">
          <p>With a honely pro subscription you’ll be equipped with the most powerful real estate data on the market.
            Access and download comprehensive property reports, home forecasts, neighborhood &amp; moving trends, and
            more with a simple search.</p>
        </div>
        <div class="subscription-dialog-content">
          <div v-if="$vuetify.breakpoint.width >= 768">
            <table class="subscription-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Free Search</th>
                  <th>Single Property Report</th>
                  <th>Honely Pro Subscription</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="name">Current Estimate</td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                </tr>
                <tr>
                  <td class="name">3 Month Forecast</td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                </tr>
                <tr>
                  <td class="name">1 Year Forecast</td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                </tr>
                <tr>
                  <td class="name">2 Year Forecast</td>
                  <td class="border-bottom"></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                </tr>
                <tr>
                  <td class="name">3 Year Forecast</td>
                  <td class="border-bottom"></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                </tr>
                <tr>
                  <td class="name">Neighberhood trends</td>
                  <td class="border-bottom"></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                </tr>
                <tr>
                  <td class="name">Buyers Score</td>
                  <td class="border-bottom"></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                </tr>
                <tr>
                  <td class="name">Property Investment Calculator</td>
                  <td class="border-bottom"></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                </tr>
                <tr>
                  <td class="name primary">Monthly email report</td>
                  <td class="border-bottom"></td>
                  <td class="border-bottom"></td>
                  <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td class="price">
                    <p>$2.99 <span>per report</span></p>
                  </td>
                  <td class="price">
                    <p>$2.99 <span>per month</span></p>
                    <!-- <p class="extra">+ $1 per extra report</p> -->
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td class="continue">
                    <button v-if="this.isCognitoUserLoggedIn" class="continue-btn"
                      @click="onClickContinue()">Continue</button>
                    <button v-else="this.isCognitoUserLoggedIn" class="continue-btn" @click="onClickContinue()">Free
                      sign up</button>
                  </td>
                  <td class="purchase">
                    <button class="bg-primary" @click="goToSubscriptionPage(299)"
                      @disabled="!estimatedValueLoaded">Purchase</button>
                  </td>
                  <td class="purchase">
                    <button class="bg-primary" @click="goToSubscriptionPage(1499)"
                      @disabled="!estimatedValueLoaded">Subscribe</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <div class="mobile-subscription-table-container">
              <table class="subscription-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Free Search</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="name">Current Estimate</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">3 Month Forecast</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">1 Year Forecast</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">2 Year Forecast</td>
                    <td class="border-bottom"></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <!-- <td class="continue" colspan="2">
                      <button v-if="searchQuery" class="continue-btn" @click="onClickContinue()">Continue</button>
                    </td> -->
                    <td class="continue" colspan="2">
                      <button v-if="this.isCognitoUserLoggedIn" class="continue-btn"
                        @click="onClickContinue()">Continue</button>
                      <button v-else="this.isCognitoUserLoggedIn" class="continue-btn" @click="onClickContinue()">Free
                        sign up</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mobile-subscription-table-container">
              <table class="subscription-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Single Property Report</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="name">Current Estimate</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">3 Month Forecast</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">1 Year Forecast</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">2 Year Forecast</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">3 Year Forecast</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">Neighberhood trends</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">Buyers Score</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">Property Investment Calculator</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="price" colspan="2">
                      <input type="checkbox" id="checkbox" v-model="checked" style="margin-right: 5px;">
                      <label for="checkbox">Sign up for monthly report</label>
                    </td>
                  </tr>
                  <tr>
                    <td class="price" colspan="2">
                      <p>$2.99 <span>per report</span></p>
                    </td>
                  </tr>
                  <tr>
                    <td class="purchase" colspan="2">
                      <button class="bg-primary" @click="clickHandler"
                        @disabled="!estimatedValueLoaded">Purchase</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <div class="mobile-subscription-table-container">
              <table class="subscription-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Honely Pro Subscription</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="name">Current Estimate</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">3 Month Forecast</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">1 Year Forecast</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">2 Year Forecast</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">3 Year Forecast</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">Neighberhood trends</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">Buyers Score</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name">Property Investment Calculator</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="name primary">15 Reports Per month</td>
                    <td class="border-bottom"><span class="mdi mdi-check"></span></td>
                  </tr>
                  <tr>
                    <td class="price" colspan="2">
                      <p>$2.99 <span>per month</span></p>
                      <p class="extra">+ $1 per extra report</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="purchase" colspan="2">
                      <button class="bg-primary" @click="goToSubscriptionPage(299)"
                        @disabled="!estimatedValueLoaded">Purchase</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>
        </div>
        <br><br>
        <div class="subscription-dialog-header">
          <div>
            <h2>Why be a pro subscriber?</h2>
            <!-- <p>Select payment method</p> -->
          </div>
        </div>
        <div class="honely-pro-description">
          <p>Real estate decisions should be approached as investment decisions. Most times, a home is one’s largest
            asset. But, never before has the consumer (buyer, seller, investor, homeowner) been armed with the proper
            data to make wiser, real-estate investment decisions.</p>
          <br>
          <p>Homeowner - monitor your home’s changing value and the climate of the market/neighborhood you’re in.</p>
          <p>Buyer/Seller - if you’re in the market for a new home, ensure your next purchase can be a fruitful one in a
            promising area. Analyze comprehensive property reports on all the homes you’re prospecting.</p>
          <p>Realtor - impress your clients with a new depth of data and analytics and arm yourself with an elevated
            understanding of home values and key market trends.</p>
          <p>Investor - if you invest in residential real estate, a honely pro subscription will be your best friend.
            Replace hours of research and underwriting with the simple search of an address or zip code.</p>
        </div>
        <login-popup :show="showLogin" @toggleShow="toggleLoginPopupShow" />
        <signup-popup :show="showSignUp" @toggleShow="toggleSignupPopupShow" />
        <search-popup :show="showSearch" @customClick="customClick" @toggleShow="toggleSearchPopupShow" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
export default {
  props: {
    searchQuery: String
  },
  components: {
    LoginPopup: () => import('@/components/login_popup/Index'),
    SearchPopup: () => import('@/components/search_popup/Index'),
    SignupPopup: () => import('@/components/login_popup/Index2'),
  },
  data() {
    return {
      showLogin: false,
      showSignUp: false,
      forecastPropertyId: null,
      forecastZipcode: null,
      estimatedValueLoaded: true,
      searchQuery: null,
      showSearch: false,
      selectedPrice: 299,
      checked: false,
      freeTrial: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isCognitoUserLoggedIn']),
  },
  mounted() {
    this.searchQuery = this.$route.query.address
    // if (this.$store.getters['auth/isCognitoUserLoggedIn'] && this.searchQuery) {
    //   this.getEstimatedValue()
    // }
    if (this.searchQuery) {
      this.getEstimatedValue()
    }
  },
  methods: {
    goToSubscriptionPage(price) {
      if (this.isCognitoUserLoggedIn) {
        if (!this.searchQuery) {
          this.selectedPrice = price
          this.showSearch = true
        } else {
          let successURL = window.location.href
          if (this.searchQuery) {
            successURL = window.location.href.split('?')[0]
            successURL = successURL.replace('pricing', '')
            successURL = successURL + "forecast?address=" + encodeURI(this.searchQuery)
          }
          this.$store.dispatch('listings/setSubscriptionMode', {
            propertyId: this.forecastPropertyId,
            zipCode: this.forecastZipcode,
            price: price,
            successURL: successURL,
            forecastAccess: false,
            searchQuery: this.searchQuery || null
          })
          this.$router.push('/smart-data-subscription')
        }
      } else {
        this.showLogin = true
      }
    },
    clickHandler() {
      if (this.checked) {
        this.goToSubscriptionPage(1499)
      } else {
        this.goToSubscriptionPage(299)
      }
    },
    customClick(value) {
      if (this.freeTrial) this.$router.push({ name: 'Smart Search', query: { address: value } })
      else {
        let successURL = window.location.href
        if (value) {
          successURL = window.location.href.split('?')[0]
          successURL = successURL.replace('pricing', '')
          successURL = successURL + "forecast?address=" + encodeURI(value)
          this.estimatedValueLoaded = false
          const requestOptions = {
            params: {
              address: value
            },
          }
          axios.get('https://api.honely.com/searches/dev/v1/forecast', requestOptions).then((response) => {
            if (response) {
              this.forecastPropertyId = response.data.property_forecast?.property_id
              this.forecastZipcode = response.data?.zipcode
              this.$store.dispatch('listings/setSubscriptionMode', {
                propertyId: this.forecastPropertyId,
                zipCode: this.forecastZipcode,
                price: this.selectedPrice,
                successURL: successURL,
                forecastAccess: false,
                searchQuery: value
              })
              if (this.isCognitoUserLoggedIn) {
                this.$router.push('/smart-data-subscription')
              } else {
                this.showLogin = true
              }
            }

          })
            .catch((error) => {
              this.estimatedValueLoaded = true
              console.log(error)
            })
        }
      }
    },
    toggleLoginPopupShow(value) {
      this.showLogin = value
    },
    toggleSearchPopupShow(value) {
      this.showSearch = value
      if (!value) this.freeTrial = false
    },
    toggleSignupPopupShow(value) {
      this.showSignUp = value
    },
    getEstimatedValue(customQuery) {
      this.estimatedValueLoaded = false
      const requestOptions = {
        params: {
          address: customQuery || this.searchQuery,
        },
      }
      axios.get('https://api.honely.com/searches/dev/v1/forecast', requestOptions).then((response) => {
        if (response) {
          this.forecastPropertyId = response.data.property_forecast?.property_id
          this.forecastZipcode = response.data?.zipcode
        }

      })
        .catch((error) => {
          this.estimatedValueLoaded = true
          console.log(error)
        })
    },
    onClickContinue() {
      this.freeTrial = true
      if (this.isCognitoUserLoggedIn) {
        if (this.searchQuery) {
          this.$router.push({ name: 'Smart Search', query: { address: this.searchQuery } })
        } else {
          this.showSearch = true
        }
      } else {
        this.showSignUp = true
      }
    },
  }
}
</script>